import { Box, Grid, Stack, Toolbar, Typography, Switch, Dialog, DialogContent, DialogTitle, TextField, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Images from '../../utils/Images';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import { CancelOutlined, CheckCircleOutline, ChevronRight, ToggleOff, ToggleOffOutlined, ToggleOnOutlined } from '@mui/icons-material';
import ManagerCalendar from '../../components/ManagerCalendar';
import { toast } from 'react-toastify';
import { styled } from '@mui/system';

const StyledSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#003049',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#D9D9D9',
  },
});

function ManagerDashboard() {
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openRecords, setOpenRecords] = useState(false);
  const userData = useSelector((state) => state.login.user); 
  const [cashierRecords, setCashierRecords] = useState([]);
  const [selectedCashierId, setSelectedCashierId] = useState(null);
  const [selectedCashierName, setSelectedCashierName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const storedOutletData = localStorage.getItem('outletData');

  const outletData = JSON.parse(storedOutletData);
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const url = `${API.BASE_URL}outlet/user/getShiftRecordsForCashiers`;
      console.log(url);
      const response = await axios.get( url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      console.log(response.data);
      setCashierRecords(response.data?.data?.cashiers || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getProductsList = async (searchText) => {
    try {
      setLoading(true);
      console.log('token', token);
      const response = await axios.get(`${API.BASE_URL}product/List?outletId=${outletData?._id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: searchText,
        }
      });
      setProducts(response.data.data.products);
      console.log('Products fetched successfully:', response.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (searchText) => {
    setSearchQuery(searchText);
    getProductsList(searchText);
  };

  const handleSwitchChange = async (productId, isExhausted) => {
    const url = isExhausted 
        ? `${API.BASE_URL}product/exhausted` 
        : `${API.BASE_URL}product/undo-exhausted`;
    const method = isExhausted ? 'post' : 'delete';

    try {
        await axios({
            method,
            url,
            data: { product: productId },
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await getProductsList();
        toast.success(isExhausted ? "Product set as exhausted!" : "Product set as back in stock!", {
            autoClose: 2000
        });
    } catch (error) {
        const errorMessage = error.response?.data?.data?.message || "An error occurred";
        console.error(isExhausted ? 'Error in setting exhausted:' : 'Error in undoing exhausted:', errorMessage);
        toast.error(errorMessage, {
            autoClose: 2000
        });
    }
};

  // const handleSwitchChange = async (productId, isExhausted) => {
  //   setLoading(true);
  //   try {
  //     if (isExhausted) {
  //       await axios.post(`${API.BASE_URL}product/exhausted`, { product: productId }, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });
  //       toast.success("Product set as exhausted!", { autoClose: 2000 });
  //     } else {
  //       await axios.delete(`${API.BASE_URL}product/undo-exhausted`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //         data: { product: productId }
  //       });
  //       toast.success("Product set back in stock!", { autoClose: 2000 });
  //     }
  //   } catch (error) {
  //     console.error('Error updating product status:', error);
  //     toast.error("Failed to update product status.", { autoClose: 2000 });
  //   } finally {
  //     getProductsList(searchQuery); // Refresh the product list
  //     setLoading(false);
  //   }
  // };
  
  useEffect(() =>{
    getProductsList();
  }, []);

  const handleOpenRecordsModal = async (cashierId) => {
    setSelectedCashierId(cashierId);
    console.log(cashierId);
    const selectedCashier = cashierRecords.find(cashier => cashier._id === cashierId);
    if (selectedCashier) {
      setSelectedCashierName(selectedCashier.name);
    }
    setOpenRecords(true);
  };

  const handleCloseRecordsModal = () => {
    setOpenRecords(false);
  };
  
  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container>
          <Grid item md={7} flexDirection={'column'}>
            <Grid container spacing={4} md={11} sx={{ borderRadius: '10px', padding: '0px 20px 15px 0px', margin: '50px 0px 0 10px'}}>
              <Grid item md={5}>
                <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff', borderRadius: '8px', padding: '15px 20px 15px 20px', justifyContent: 'center', textAlign: 'center' }}>
                  <AccountCircleOutlinedIcon sx={{ fontSize: '65px'}} />
                  <Typography sx={{ color: '#000', fontSize: '20px' }}>
                    {userData?.user?.name}
                  </Typography>
                  <Box sx={{ backgroundColor: '#003049', color: '#fff', padding: '10px 0px 10px 0px', borderRadius: '30px' }}>
                    <Typography sx={{ fontSize: '14px' }}>Manager</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={7}>
                <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff', borderRadius: '8px', padding: '0px 0 7px 0', justifyContent: 'center', textAlign: 'center' }}>
                  <Typography sx={{ color: '#000', fontSize: '80px' }}>
                    556
                  </Typography>
                  <Box sx={{ padding: '10px 0px 10px 0px' }}>
                    <Typography sx={{ fontSize: '16px' }}>Total day completed orders</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container md={11} sx={{ marginTop: '15px' }}>
              <Grid item md={4}>
                <Typography sx={{ fontSize: '15px', margin: '16px 0 0 40px' }}>Stock Inventory</Typography>
              </Grid>
              <Grid item md={8}>
                <TextField
                    fullWidth
                    sx={{
                      backgroundColor: '#fff',
                      marginTop: '5px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                      },
                      '& input': {
                        height: '10px',
                      },
                    }}
                    placeholder='Search'
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchQuery}
                    onChange={(e) => { handleSearch(e.target.value); }}
                  />
              </Grid>
            </Grid>
            <Grid container md={11} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 40px'}}>
                { products.length === 0 ? (
                  <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                    No products found.
                  </Typography>
                ) : ( 
                <>
                  <Grid item md={2} sx={{ padding: '20px 10px 20px 10px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Item No</Typography>
                  </Grid>
                  <Grid item md={6} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Product</Typography>
                  </Grid>
                  <Grid item md={4} sx={{ padding: '20px 0px 20px 0px' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Set as Out of Stock</Typography>
                  </Grid>
                  {products.map((product, index) => (
                    <React.Fragment key={product._id}>
                      <Grid item md={2} sx={{ backgroundColor: '#EDF9FF', padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{  fontSize: '14px'}}>{ index + 1 }</Typography>
                      </Grid>
                      <Grid item md={6} sx={{ backgroundColor: '#EDF9FF', padding: '20px 0px 20px 0px', borderRight: '1px solid grey' }}>
                          <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <img src={product.displayImage} alt={product.name} style={{ height: '20%', width: '20%'}} 
                              onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = Images.product;
                            }}
                            />
                            <Box>
                              <Typography sx={{ fontSize: '14px', fontWeight: 'bold'}}>{product.name}</Typography>
                              <Typography sx={{ fontSize: '14px'}}>Price: ₹{product.mrp}</Typography>
                            </Box>
                          </Stack>
                      </Grid>
                      <Grid item md={4} sx={{ backgroundColor: '#EDF9FF', padding: '20px 0', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <IconButton onClick={() => handleSwitchChange(product._id, !product.isExhausted)}>
                          {product.isExhausted ? (
                            <ToggleOnOutlined style={{ fontSize: '30px', color: 'red' }} />
                          ) : (
                            <ToggleOffOutlined style={{ fontSize: '30px', color: 'green' }} />
                          )}
                        </IconButton>
                        {/* <StyledSwitch
                          checked={product.isExhauted}
                          onChange={(e) => handleSwitchChange(product._id, e.target.checked)}
                        /> */}
                        <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
                          {product.isExhausted ? 'Set as in Stock' : 'Set as Out of Stock'}
                        </Typography>
                      </Grid>
                    </React.Fragment>
                  ))}
                </>
                )}
            </Grid>
          </Grid>
          <Grid item md={5}>
            <Grid container md={12} sx={{ borderRadius: '8px', margin: '50px 0px 0 20px'}}>
              <Grid item md={11} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff', borderRadius: '4px', padding: '15px 0px 20px 0px' }}>
                <Typography sx={{ color: '#000', fontSize: '18px', textAlign: 'center', paddingBottom: '10px' }}>Manage Cashiers</Typography>
                {cashierRecords.map((cashier, index) => (
                  <Box key={cashier._id} sx={{ backgroundColor: index % 2 === 0 ? '#EDF9FF' : '#FFF', padding: '10px 20px 15px 20px' }}>
                    <Typography sx={{ color: '#000', fontSize: '14px', fontWeight: '600' }}>{cashier.name}</Typography>
                    <Typography sx={{ color: '#000', fontSize: '11px', paddingBottom: '8px' }}>In Out Time today</Typography>
                    <Grid container justifyContent={'space-around'}>
                      <Grid item md={7} sx={{ backgroundColor: index % 2 === 0 ?  '#FFF' : '#EDF9FF', borderRadius: '5px', padding: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Stack direction={'row'}>
                          <Typography sx={{ fontWeight: '600', fontSize: '12px' }}>In : 8:02am</Typography>
                          <Typography sx={{ marginLeft: '12px', fontWeight: '600', fontSize: '12px' }}>Out : 8:02am</Typography>
                        </Stack>
                      </Grid>
                      <Grid item md={4} className='cursor-p' onClick={() => handleOpenRecordsModal(cashier._id)} sx={{ backgroundColor: index % 2 === 0 ?  '#FFF' : '#EDF9FF', borderRadius: '5px', padding: '6px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Stack direction={'row'}>
                          <Typography sx={{ fontWeight: '600', fontSize: '10px' }}>See previous <br/>records</Typography>
                          <ChevronRight />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openRecords} onClose={handleCloseRecordsModal} maxWidth="lg">
        <DialogTitle>Shift Record : {selectedCashierName}</DialogTitle>
        <DialogContent>
          <ManagerCalendar selectedCashierId={selectedCashierId} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ManagerDashboard;