import { Typography } from '@mui/material';
import React from 'react';

const PrimaryButton = ({ title, onClick, disabled, spinner = true, disabledTitle, style, disabledColor = '#003049' }) => {
    
    const handleClick = (event) => {
        if (!disabled && onClick) {
            onClick();
        }
        event.currentTarget.blur();
    };

    return (
        <button
            // onClick={disabled ? null : onClick}
            onClick={handleClick}
            disabled={disabled}
            className={`primary-button-container ${disabled ? 'disabled' : ''}`}
            style={{
                ...style,
                backgroundColor: disabled ? disabledColor : style.backgroundColor,
            }}
        >
            {disabled && spinner && (
                <div className="spinner">
                    <div className="circle"></div>
                </div>
            )}

            <span className='title'>{disabled ? disabledTitle : title}</span>
        </button>
    );
};

export default PrimaryButton;
