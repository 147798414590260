import React, { useEffect, useState } from 'react';
import PrimaryButton from './PrimaryButton';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';

const pageStyle = `
  @page {
    size: 50mm 75mm;
    margin: 0;
  }

  body {
    text-transform: uppercase;
  }

  @media all {
    .pageBreak {
      display: none;
    }
  }

  @media print {
    body {
      font-family: "Courier Prime", monospace;
    }
    .bodyBox{
      padding: 10px !important;
      page-break-before: always; 
    }
    h3{
      text-align: center !important;
    }
    .orderNoAndTime{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-bottom: 8px;
      margin-top: -10px;
    }
    .orderNo{
      font-weight: 800;
      margin: 0;
      font-size: 20px;
    }
    .readyTime{
      font-weight: 800;
      margin: 0;
      font-size: 20px;
    }
    .productName{
      font-size: 14px;
      font-weight: 800;
      margin: 0;
      padding-top: 10px !important;
    }
    .productQty{
      font-size: 14px;
      font-weight: 800;
      margin: 0;
      text-transform: capitalize !important;
    }
    ul {
      margin: 0;
      padding: 5px;
    }
    li {
      font-size: 12px;
      margin: 0;
    }
    .pageBreak {
      page-break-before: always;
    }
  }
`;

const KOTPrinter = ({ orders, orderDetail }) => {
  const [dataArr, setDataArr] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasFood, setHasFood] = useState(false);
  const [hasBeverage, setHasBeverage] = useState(false);

  useEffect(() => {
    if (orders) {
      const updatedDataArr = [];
      const productMap = {};

      // Aggregate quantities by product name and addons
      orders?.orderItems.forEach((orderItem) => {
        const addonsKey = orderItem.addons?.map((addon) => addon.selectedValue).join(',') || 'no_addons';
        const productKey = `${orderItem.product.name}_${addonsKey}`;
        
        if (!productMap[productKey]) {
          productMap[productKey] = {
            ...orderItem,
            quantity: 0,
          };
        }
        productMap[productKey].quantity += orderItem.quantity;
      });

      // Convert productMap back to an array
      Object.values(productMap).forEach((orderItem) => {
        updatedDataArr.push(orderItem);
      });

      setDataArr(updatedDataArr);

      // Check if there are FOOD or BEVERAGE items
      setHasFood(updatedDataArr.some((order) => order.product.classType === 'FOOD'));
      setHasBeverage(updatedDataArr.some((order) => order.product.classType === 'BEVERAGE'));
    }
  }, [orders]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handlePrintOption = (type) => {
    handleDialogClose();
    printContent(type);
  };

  const printContent = (type) => {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    const printDocument = iframe.contentWindow || iframe.contentDocument;
    const doc = printDocument.document || printDocument;
    doc.open();
    doc.write('<html><head><style>');
    doc.write(pageStyle);
    doc.write('</style></head><body>');

    if (type === 'FOOD' || type === 'BEVERAGE') {
      const filteredItems = dataArr.filter((order) => order.product.classType === type);
      const title = type === 'FOOD' ? 'FOOD' : 'BEVERAGE';
      doc.write(`
        <div class="bodyBox">
          <h3 className="title">${title}</h3>
          <div class="orderNoAndTime">
            <p class="orderNo">#${orderDetail.order_sequence}${orderDetail.order_no}</p>
            <p class="readyTime">(${orderDetail.ordersType === 'PICKUP' ? 'P' : orderDetail.ordersType === 'DELIVERY' ? 'D' : ''})</p>
            <p class="readyTime">${orderDetail.readyTime}</p>
          </div>
      `);
      filteredItems.forEach((order) => {
        doc.write(`
          <p class="productName">${order.product.name}</p>
          <p class="productQty">QTY: ${order.quantity} Nos</p>
        `);
        if (order.addons?.length > 0) {
          doc.write('<ul>');
          order.addons.forEach((addon) => {
            doc.write(`<li>${addon.selectedValue}</li>`);
          });
          doc.write('</ul>');
        }
      });
      doc.write('</div>');
    } else if (type === 'ALL') {
      const foodItems = dataArr.filter((order) => order.product.classType === 'FOOD');
      const beverageItems = dataArr.filter((order) => order.product.classType === 'BEVERAGE');
      if (foodItems.length > 0) {
        doc.write(`
          <div class="bodyBox">
            <h3 className="title">FOOD</h3>
            <div class="orderNoAndTime">
              <p class="orderNo">#${orderDetail.order_sequence}${orderDetail.order_no}</p>
              <p class="readyTime">(${orderDetail.ordersType === 'PICKUP' ? 'P' : orderDetail.ordersType === 'DELIVERY' ? 'D' : ''})</p>
              <p class="readyTime">${orderDetail.readyTime}</p>
            </div>
        `);

        foodItems.forEach((order) => {
          doc.write(`
            <p class="productName">${order.product.name}</p>
            <p class="productQty">QTY: ${order.quantity} Nos</p>
          `);
          if (order.addons?.length > 0) {
            doc.write('<ul>');
            order.addons.forEach((addon) => {
              doc.write(`<li>${addon.selectedValue}</li>`);
            });
            doc.write('</ul>');
          }
        });
        doc.write('</div>');
        doc.write('<div class="pageBreak"></div>'); // Page break for the next page (beverage)
      }

      // Write heading and beverage items on the second page
      if (beverageItems.length > 0) {
        doc.write(`
          <div class="bodyBox">
            <h3 className="title">BEVERAGE</h3>
            <div class="orderNoAndTime">
              <p class="orderNo">#${orderDetail.order_sequence}${orderDetail.order_no}</p>
              <p class="readyTime">(${orderDetail.ordersType === 'PICKUP' ? 'P' : orderDetail.ordersType === 'DELIVERY' ? 'D' : ''})</p>
              <p class="readyTime">${orderDetail.readyTime}</p>
            </div>
        `);

        beverageItems.forEach((order) => {
          doc.write(`
            <p class="productName">${order.product.name}</p>
            <p class="productQty">QTY: ${order.quantity} Nos</p>
          `);
          if (order.addons?.length > 0) {
            doc.write('<ul>');
            order.addons.forEach((addon) => {
              doc.write(`<li>${addon.selectedValue}</li>`);
            });
            doc.write('</ul>');
          }
        });
        doc.write('</div>');
      }
    }

    doc.write('</body></html>');
    doc.close();
    iframe.focus();
    iframe.contentWindow.print();
  };

  return (
    <>
      <PrimaryButton onClick={handleDialogOpen} disabledTitle={'Printing'} title={'Print KOT'} style={{ borderRadius: '30px' }} />

      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth sx={{ margin: '0 20px 0 20px' }}>
        <DialogTitle>Select What To Print:</DialogTitle>
        <DialogActions>
          <PrimaryButton onClick={() => handlePrintOption('ALL')} disabledTitle={'Printing'} title={'ALL'} style={{ borderRadius: '30px' }} />
          <PrimaryButton
            spinner={false}
            onClick={() => handlePrintOption('FOOD')}
            disabled={!hasFood}
            disabledTitle={'No Food Items'}
            title={'FOOD'}
            style={{ borderRadius: '30px' }}
          />
          <PrimaryButton
            spinner={false}
            onClick={() => handlePrintOption('BEVERAGE')}
            disabled={!hasBeverage}
            disabledTitle={'No Beverage Items'}
            title={'BEVERAGE'}
            style={{ borderRadius: '30px' }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KOTPrinter;
